<template>

<div class="loadFail table-hook fadeInRight">
    <el-table :data="tableData">
        <el-table-column label="卡编号" prop="iccidMark" width="180px"></el-table-column>
        <el-table-column label="套餐名称" prop="setMealName" show-overflow-tooltip></el-table-column>
        <el-table-column label="卡来源" prop="category" show-overflow-tooltip>
            <template slot-scope="scope">
                <!-- <span v-if="scope.row.category===1">电信</span>
                <span v-if="scope.row.category===2" >其他卡务</span>
                <span v-if="scope.row.category===3" >移动</span>
                <span v-if="scope.row.category===4" >联通</span>
                <span v-if="scope.row.category===5" >DCP电信</span>
                <span v-if="scope.row.category===6" >联通Q</span>
                <span v-if="scope.row.category===7" >新移动</span>
                <span v-else>全部</span> -->
                <span>{{_getCategory(scope.row.category,1)}}</span>
            </template>  
        </el-table-column>
        <el-table-column label="原本过期时间" prop="expireDate" show-overflow-tooltip></el-table-column>
        <el-table-column label="导入过期时间" prop="importExpireDate" show-overflow-tooltip></el-table-column>
        <el-table-column label="导入类型" prop="type">
            <template slot-scope="scope">
                <span v-if="scope.row.type===1">过期时间</span>
                <span v-if="scope.row.type===2" >窜卡复机</span>
                <span v-if="scope.row.type===4" >设置阀值</span>
                <span v-if="scope.row.type===5" >删除卡</span>
                <span v-if="scope.row.type===6" >窜卡</span>
                <span v-if="scope.row.type===7" >变更套餐</span>
                <span v-if="scope.row.type===9" >机卡重绑</span>
                <span v-if="scope.row.type===10" >网卡回收</span>
                <span v-if="scope.row.type===11" >网卡注销</span>
                <span v-if="scope.row.type===12" >不续费充值</span>
                <span v-if="scope.row.type===13" >增减流量</span>
                <span v-if="scope.row.type===16" >导入IMEI库</span>
                <span v-else>全部</span>
            </template>   
        </el-table-column>
        <el-table-column label="描述" prop="remark" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" prop="opt">
            <template slot-scope="scope">
                <span v-if="scope.row.status === 3">
                    <a class="margin-right15" href="javascript:void(0)" @click="recharge(scope.row)">充值</a>
                    <a class="margin-right15" href="javascript:void(0)" @click="updateStatus(scope.row.id)">放弃</a>
                </span>
                <a href="javascript:void(0)" @click="detail(scope.row.id)">详情</a>
            </template>
        </el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 15, 50,100,1000]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div> 

    <el-dialog title="分销卡失败详情" :visible.sync="dialogVisible">
        <div>
            <p class="dia-title">卡编号:</p>
            <el-input v-model="iccidMark" readonly></el-input>
        </div>
        <div>
            <p class="dia-title">套餐名称:</p>
            <el-input v-model="setMealName" readonly></el-input>
        </div>
        <div>
            <p class="dia-title">卡来源:</p>
            <el-input v-model="categoryId" readonly></el-input>
        </div>

        <div>
            <p class="dia-title">原本过期时间:</p>
            <el-input v-model="expireDate" readonly></el-input>
        </div>
        <div>
            <p class="dia-title">导入过期时间：:</p>
            <el-input v-model="importExpireDate" readonly></el-input>
        </div>
        <div>
            <p class="dia-title">导入类型:</p>
            <el-input v-model="type" readonly></el-input>
        </div>

        <div>
            <p class="dia-title">错误描述:</p>
            <el-input v-model="remark" readonly></el-input>
        </div>
    </el-dialog>

    <el-dialog title="充值套餐" :visible.sync="dialogRecharge">

        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="rechargeSubmit">确定</el-button>
            <el-button type="primary" class="themed-button" @click="rechargeReset">重置</el-button>
            <el-button type="primary" class="themed-button" @click="dialogRecharge = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">

import {importListFail,findById,update} from '@/api/cardManagement/batchCard.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import dictGroup from '@/mixins/dictGroup.js'

export default {
    mixins:[dictGroup],
    data(){
        return {
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogVisible:false,
            iccidMark:'',
            setMealName:'',
            categoryId:'',
            expireDate:'',
            importExpireDate:'',
            type:'',
            remark:'',
            dialogRecharge:false
        }
    },
    mounted(){
        this._importListFail()
    },
    methods:{
        recharge(data){
            // if(data.category === 2){
            //     messageBox(this,"暂不支持第三方套餐充值!");
            //     return;
            // }
            let params = {}
            params.iccidMark = data.iccidMark
            params.setMealId = data.setMealId
            params.isId = data.id
        },
        rechargeSubmit(){},
        rechargeReset(){},
        updateStatus(id){
            confirmBox(this,"确定放弃吗？",()=>{
                let params = {
                    id,
                    status:2
                }
                update(params).then((res)=>{
                    this._importListFail()
                })
            })
        },
        detail(id){
            this.dialogVisible = true
            let params = {id}
            findById(params).then((res)=>{
                let data = res.entity
                this.iccidMark = data.iccidMark
                this.setMealName = data.setMealName
                this.categoryId = data.categoryId
                this.expireDate = data.expireDate
                this.importExpireDate = data.importExpireDate
                this.type = ((type)=>{
                    if (type == 1) return "过期时间"; else if (type == 2) return "窜卡复机"; else if (type == 3) return "停机"; else if (type == 4) return "设置阀值"; else if (type == 5) return "删除卡"; else if (type == 6) return "窜卡"; else if (type == 7) return "变更套餐"; else if (type == 9) return "机卡重绑"; else if (type == 10) return "网卡回收"; else if (type == 11) return "网卡注销"; else if (type == 12) return "不续费充值"; else if (type == 13) return "增减流量"; else if (type == 16) return "导入IMEI库";  else if (type == 17) return "修改业务类型"; 
                    
                })(data.type)
                 
                this.remark = data.remark
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._importListFail()  
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._importListFail()
        },
        _importListFail(){
            let params = this.$route.query
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            importListFail(params).then((res) =>{
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        }
    }
}
</script>
<style>
     .loadFail .dia-title{margin-top:10px;margin-bottom:6px;}
     .margin-right15{margin-right:15px;}
</style>
